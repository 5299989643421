/* eslint-disable max-len */
import {documentTypesAU} from '@Shared/constants/document-types-AU';
import {EnvironmentConfiguration} from './interfaces/environment-configuration';
import {AustralianStates} from '@Shared/enums/locations';
import {auDisclaimers} from './config/disclaimers-config/disclaimers.au';
import {auFilters} from './config/filters-config/filters.au';
import {auPropertyData} from './config/property-data-config/property-data-au';
import {BasemapMapboxConfiguration} from './interfaces/basemap-mapbox-configuration';
import {BasemapAzureMapsConfiguration} from './interfaces/basemap-azuremaps-configuration';
import {dateFormat} from './config/date-format/date-format';
import {customOverlaysAU} from './config/custom-overlay-config/custom-overlay-au-acugis';
import {sortOptions} from './config/sort-options/sort-options';
import {COMMON_GREETINGS} from './config/common-greetings/common-greetings';
import {ProductFeatureCode} from '@Shared/enums/product-feature-code';
import {mainImageTypesAU} from './config/image-options/main-image-types-au';

const instanceName = 'valocitytest';
const geoServerBaseUri = 'https://valocity.webgis1.com/geoserver';

export const environment: EnvironmentConfiguration = {
  apiGatewayUri: 'https://appgateway.valocity-test.com.au',
  auth: {
    instance: `https://${instanceName}.onmicrosoft.com`,
    issuer: `https://${instanceName}.b2clogin.com/a0d6867a-3a3b-4863-a8fa-d5ce8db990e4/v2.0/`,
    loginUrl: `https://${instanceName}.b2clogin.com/${instanceName}.onmicrosoft.com/b2c_1a_signin/oauth2/v2.0/authorize`,
    tokenEndpoint: `https://${instanceName}.b2clogin.com/${instanceName}.onmicrosoft.com/b2c_1a_signin/oauth2/v2.0/token`,
    discoveryDocumentUrl: `https://${instanceName}.b2clogin.com/${instanceName}.onmicrosoft.com/b2c_1a_signin/v2.0/.well-known/openid-configuration`,
    clientId: 'dd55d104-d415-4a03-b878-42792b7bbcc3',
  },
  brandingLabel: 'REIP Nexus',
  countryCode: 'AU',
  localeID: 'en-AU',
  currencyCode: 'AUD',
  timezone: 'Australia/Sydney',
  logoSrc: 'assets/logos/REIP Nexus PNGs/REIP Nexus Orange-White.png',
  production: false,
  appInsights: {
    // For ai-aue-test-valocity-au-mon-1
    connectionString:
      'InstrumentationKey=c3622f38-cc4b-4cb1-a060-066f6c42b9e1;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/',
  },
  productId: 'D61E338C-035E-465F-92ED-D4953CFD2F40',
  productLink: 'https://reipnexus.com.au',
  pinIconSrc: 'assets/icons/reipNexus/android-chrome-96x96.png',
  vendorClientId: '13E789DE-EC54-4828-BE86-D7E2BD77E510',
  enableHectareDisplay: false,
  enableCommissionSchedule: false,
  enableRadiusSearch: true,
  enablePropertyEstimate: true,
  enableAppraisalsSection: true,
  enablePlanningDevelopment: true,
  enableNewSearch: false,
  enableRentalCma: true,
  enableDashboardFilteringTabs: true,
  enableAbbreviationSearch: false,
  enableNewPreparedFor: false,
  enableMarketHistoryDetails: false,
  enableViewedSavedTabs: false,
  enableMarketHistoryViewDetails: false,
  enableQuickLinks: false,
  enablePersistedRecentSearches: false,
  enableProspecting: false,
  enableMapZones: false,
  enableCpms: false,
  enableOrderDocumentsV2: false,
  enableFolioNumbers: false,
  enableCertificateofTitleSummary: false,
  enablePriceRangeToggle: true,
  enableBuildingNameSearch: false,
  enablePrimaryAddressSearch: false,
  enableComprehensiveEstimate: true,
  enableCompanyProfileDetail: true,
  enableMarketingContacts: false,
  enableCustomiseDigitalCmaPage: false,
  enablePropertySummaryLayoutV2: false,
  enableCmaTrackingEventsToggle: true,
  enableListingPriceView: false,
  enableOnePagePropertyReport: false,
  imagesOptions: {
    hasAerialImages: false,
    hasFloorPlanImages: true,
    mainImageTypes: mainImageTypesAU,
    listingsImagesOptions: {
      showOnComparableListings: true,
      showOnComparableSales: true,
      showOnPropertySummary: true,
      showOnSearchResults: true,
    },
  },
  propertyReport: {
    minComparableProperties: 5,
    maxComparableProperties: 12,
  },
  favicon: 'assets/icons/reipNexus/favicon.png',
  filters: auFilters,
  propertyData: auPropertyData,
  documentTypes: documentTypesAU,
  basemaps: {
    defaultZoomLevel: 17,
    maps: [
      {
        provider: 'Mapbox',
        configuration: {
          attribution: `© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>`,
          accessToken:
            // this is a designated token for Nexus Web in AU TEST environment. please do not use this in any other environment or any other place.
            'pk.eyJ1IjoidmFsb2NpdHktdGVjaG5vbG9neSIsImEiOiJjbDZsb3AwaXEwZG53M2pvMzBzNDM2OXM5In0.IXkdZ3tgo0n8DrCc_qho3g',
          styles: [
            {
              name: 'Road',
              url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=',
              maxZoom: 22,
              maxNativeZoom: 22,
            },
          ],
        } as BasemapMapboxConfiguration,
      },
      {
        provider: 'AzureMaps',
        configuration: {
          attribution: '',
          authType: 'anonymous',
          clientId: 'b6cf5c4e-bfe8-4e44-a71d-72e73694547d',
          tiles: [
            {
              name: 'Aerial',
              tilesetId: 'microsoft.imagery',
              maxZoom: 22,
              maxNativeZoom: 19,
            },
          ],
        } as BasemapAzureMapsConfiguration,
      },
    ],
  },
  customOverlayMaps: customOverlaysAU(geoServerBaseUri),
  mapProperties: {
    geoServerUrl: `${geoServerBaseUri}/Australia/ows`,
    typeName: 'Australia:aus_centroids',
    property: 'parcel_id',
    geomProperty: 'geom',
  },
  disclaimers: auDisclaimers,
  dashboardLogoTile: {
    title: 'Powered by',
    link: 'https://reip.com.au/',
    logo: 'assets/logos/nexus-au-partner-logo_v2.png',
  },
  areaUnitConversionThreshold: 10000,
  gstPercentage: 10,
  showStatementOfInformation: AustralianStates.VIC,
  recentSalesFilterEnabledStates: [AustralianStates.VIC],
  knowledgeBaseUrl: 'https://j8fg03rdjp13.valocityglobal.com/knowledge',
  mouseflowTrackingCodeEnabled: false,
  dateFormat,
  sortOptions,
  disabledOwnerLinkStates: [AustralianStates.VIC, AustralianStates.NSW],
  launchDarklyClientSDKKey: '656fe372c5474e10823fce77',
  availableLocalSchoolTabs: ['Primary', 'Secondary', 'Combined'],
  greetings: COMMON_GREETINGS,
  prospectingStatisticsMaxNumber: 1000,
  productFeatures: [ProductFeatureCode.certificateOfTitle, ProductFeatureCode.iValReport],
  maxCoverLetterLength: 2000,
  id4meBaseUrl: 'https://id4me-search-dev.azurewebsites.net',
  storyLaneOptions: {
    homeComponentDemoUri: 'https://valocity.storylane.io/demo/ga4wfmfva6mm',
  },
  supportEmail: 'support@reipnexus.com.au',
};
